<template>
  <div>
    <div class="describe-content">
      <ul>
        <li v-for="itm in list">
          <div>
            <img ondragstart="return false;" :src="itm.icon" />
            <h4>{{ itm.title }}</h4>
          </div>
          <section>
            <p>{{ itm.text }}</p>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      list: [
        {
          icon: "/images/icon/icon_007.svg",
          title: "完璧な機能",
          text: "美しさ、オーディオ処理技術、低フリーズ率、ライブ画面記録、シームレスな解像度切り替えなどを統合する機能を備えており、マルチターミナル適応とアップリンクビットレート自己適応をサポートします。",
        },
        {
          icon: "/images/icon/icon_008.svg",
          title: "グローバルアクセラレーション",
          text: "CDNアクセラレーションノードのグローバル展開は2000以上を超え、北米、ヨーロッパ、東南アジアなど、50を超える国と地域をカバーしています。",
        },
        {
          icon: "/images/icon/icon_009.svg",
          title: "プロのビデオ処理",
          text: "大規模なマルチメディアデータの場合、クラウドトランスコーディングとオーディオおよびビデオ処理サービスを提供します。",
        },
        {
          icon: "/images/icon/icon_010.svg",
          title: "セキュリティと盗難防止",
          text: "リファラーのブラックリストとホワイトリスト、HTTPSセキュリティアクセラレーション、URL暗号化などの複数のライブ暗号化テクノロジーにより、万能の検出と保護が実現され、著作権侵害が防止されます。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.describe-content {
  margin: 40px auto 0;
  text-align: left;
  > ul {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      padding: 30px 40px;
      box-sizing: border-box;
      width: calc(100% / 2 - 2px);
      max-width: calc(100% / 2);
      background: #fff;
      height: auto;
      border-left: 1px solid rgba(56, 96, 244, 0.1);
      &:nth-of-type(1),
      &:nth-of-type(2) {
        border-bottom: 1px solid rgba(56, 96, 244, 0.1);
      }
      &:nth-of-type(2n + 1) {
        border-left: 0;
      }
      > div {
        > img {
          width: 45px;
          height: 45px;
          vertical-align: middle;
          background-position: center;
        }

        > h4 {
          margin-left: 10px;
          display: inline-block;
          font-size: 20px;
          vertical-align: middle;
        }
      }
      > section {
        margin-top: 15px;
        > p {
          color: #7a8ca6;
          font-size: 15px;
        }
      }
      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
