<template>
  <div>
    <section class="orenji-layer layer1">
      <header-view />
      <div class="orenji-bg bg1"></div>
      <div class="orenji-home-content">
        <h2 class="fadeInDownBig animated delay1">クラウドオンデマンド</h2>
        <p class="txt fadeInDown animated delay2">
          安定、安全、弾性、高性能のクラウドコンピューティングサービス
        </p>
      </div>
    </section>
    <section class="orenji-layer layer2">
      <div class="orenji-bg bg2"></div>
      <div class="orenji-home-content">
        <h2 class="fadeInDownBig">クラウドオンデマンド</h2>
        <p class="txt">
          クラウドオンデマンド（ビデオオンデマンド、VOD）は、オレンジ色のクラウドテクノロジーとインフラストラクチャの構築の蓄積に基づいており、オーディオとビデオのキャプチャとアップロード、ストレージ管理、自動トランスコーディング処理など、オーディオとビデオのアプリケーション関連のニーズを顧客に提供します。高速再生、メディアリソース管理、およびオーディオビデオ通信サービスは、ワンストップのVPaaSソリューションです。
        </p>
      </div>
    </section>
    <section class="orenji-layer layer3">
      <div class="orenji-bg bg3"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">製品説明</h2>
        <detail-view />
      </div>
    </section>
    <section class="orenji-layer layer4">
      <div class="orenji-bg bg4"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">ユーザーグループ</h2>
        <group-view />
      </div>
    </section>
    <div class="orenji-layer layer5">
      <div class="orenji-bg bg5"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">クラウドオンデマンド価格</h2>
        <price-view />
      </div>
    </div>
    <section class="orenji-layer layer6">
      <footer-view />
    </section>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";
import footerView from "@/components/layout/footer";
import detailView from "@/components/orenji/vod/detail";
import groupView from "@/components/orenji/vod/group";
import priceView from "@/components/orenji/vod/price";

export default {
  name: "orenji",
  computed: {},
  components: {
    headerView,
    footerView,
    detailView,
    groupView,
    priceView,
  },
};
</script>

<style lang="less" scoped>
.orenji-layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &.layer1 {
    top: 0;
  }

  &.layer2 {
    top: 100%;
  }

  &.layer3 {
    top: 200%;
    color: #333333;
    background: #f4f4f4;
  }

  &.layer4 {
    top: 300%;
    background: #ffffff;
  }

  &.layer5 {
    top: 400%;
    color: #333333;
    background: #ffffff;
  }
  &.layer6 {
    top: 500%;
    height: 219px;
  }
}

.orenji-bg {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;

  &.bg1 {
    background-image: url("../../../public/images/video_1.png");
  }

  &.bg2 {
    background-image: url("../../../public/images/video_2.png");
  }

  &.bg4 {
    background-image: url("../../../public/images/video_3.png");
  }
  &.bg5 {
    background: #fff;
  }
}

.text {
  margin: 0 40px;
}

.orenji-home-content {
  width: 1320px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  font-weight: normal;
  text-align: center;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  h2 {
    font-weight: 300;
    letter-spacing: 0.3rem;
    font-size: 50px;
    text-align: center;
    margin-bottom: 3rem;
  }
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.delay1 {
  .durationDelay(1s, 0.5s);
}

.delay2 {
  .durationDelay(1s, 1s);
}

.delay3 {
  .durationDelay(1s, 1.5s);
}

.delay4 {
  .durationDelay(1s, 2s);
}
</style>