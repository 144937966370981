<template>
  <ul class="price-wrap">
    <li v-for="(itm, idx) in list" :class="{ head: idx === 0 }" :key="idx">
      <section>{{ itm.package }}</section>
      <section>{{ itm.time }}</section>
      <section>{{ itm.play }}</section>
      <section>{{ itm.money }}</section>
      <section v-if="itm.pay">{{ itm.pay }}</section>
      <section
        v-if="!itm.pay"
        class="pay"
        @click="hanleTab(itm.money, idx + 8)"
      >
        購入
      </section>
    </li>
  </ul>
</template>

<script>
export default {
  name: "price",
  data() {
    return {
      list: [
        {
          package: "パッケージ",
          time: "トランスコード時間",
          play: "再生回数",
          money: "価格",
          pay: "支払い",
        },
        {
          package: "テスト版",
          time: "300分",
          play: "1000回",
          money: "175円",
          tab: "/pay",
        },
        {
          package: "経済版",
          time: "9000分",
          play: "80000回",
          money: "6180円",
          tab: "/pay",
        },
        {
          package: "経済版2",
          time: "9000分",
          play: "90000回",
          money: "7130円",
          tab: "/pay",
        },
        {
          package: "特恵版",
          time: "10000分",
          play: "100000回",
          money: "8826円",
          tab: "/pay",
        },
        {
          package: "特恵版2",
          time: "20000分",
          play: "200000回",
          money: "14262円",
          tab: "/pay",
        },
        {
          package: "標準版",
          time: "30000分",
          play: "300000回",
          money: "26425円",
          tab: "/pay",
        },
        {
          package: "旗艦版",
          time: "50000分",
          play: "500000回",
          money: "39629円",
          tab: "/pay",
        },
        {
          package: "超旗艦版",
          time: "100000分",
          play: "1000000回",
          money: "79258円",
          tab: "/pay",
        },
      ],
    };
  },
  methods: {
    hanleTab(item, index) {
      const ems = item.replace("円", "");
      this.$router.push(`/pay?money=${ems}&&number=${index}`);
    },
  },
};
</script>

<style lang="less" scoped>
.price-wrap {
  width: 1280px;
  margin: 0 auto;

  > li {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: #324065;

    > * {
      padding: 20px 30px;
    }

    .line2 {
      margin-top: -10px;
    }

    > section {
      &:nth-of-type(1) {
        width: 20%;
      }

      &:nth-of-type(2) {
        width: 20%;
      }

      &:nth-of-type(3) {
        width: 20%;
      }

      &:nth-of-type(4) {
        width: 20%;
      }

      &:nth-of-type(5) {
        width: 20%;
      }
    }

    &.head {
      color: #195abe;
    }

    &:nth-of-type(2n-1) {
      background: rgba(25, 90, 190, 0.05);
    }
  }
}
.pay {
  cursor: pointer;
  color: #f08c1b;
  &:hover {
    text-decoration: underline;
    color: #f08c1b;
  }
}
</style>
