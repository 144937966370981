<template>
  <div>
    <div class="describe-content">
      <ul>
        <li v-for="itm in list">
          <section>
            <img ondragstart="return false;" :src="itm.icon" />
            <p>{{ itm.title }}</p>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      list: [
        {
          icon: "/images/icon/icon_016.svg",
          title: "ゲームライブ放送プラットフォーム",
        },
        {
          icon: "/images/icon/icon_012.svg",
          title: "オンライン教育プラットフォーム",
        },
        { icon: "/images/icon/icon_014.svg", title: "Eコマースライブ放送" },
        {
          icon: "/images/icon/icon_013.svg",
          title: "新しいメディアプラットフォーム",
        },
        { icon: "/images/icon/icon_015.svg", title: "タレントライブ" },
        {
          icon: "/images/icon/icon_011.svg",
          title: "短いビデオプラットフォーム",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.describe-content {
  margin: 40px auto 0;
  text-align: left;
  > ul {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      padding: 30px 10px;
      box-sizing: border-box;
      width: calc(100% / 5);
      max-width: calc(100% / 5);
      height: auto;
      text-align: center;
      > section {
        > img {
          display: block;
          width: 45px;
          margin: 0 auto 15px;
          background-position: center;
        }

        > p {
          margin-left: 10px;
          display: inline-block;
          font-size: 15px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
